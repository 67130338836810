export interface TariffCoaMappingEntity{

    id?: number | null,
    tariffType?: string | null,
    tariffCode?: string | null,
    raType?: string | null,
    revenueAccountCode?: string | null,
    costCenterCode?: string | null,
    productServiceCode?: string | null,
    salesChannelCode?: string | null,
    projectCode?: string | null,
    spareCode?: string | null,
    mappingType?: string | null,
    activeInd: string | null,

    [key: string]: string | boolean | number | null | Object | undefined
}

export const EMPTY_TARIFF_COA_MAPPING_ENTITY : TariffCoaMappingEntity = {

    id: 0,
    tariffType: '',
    tariffCode: '',
    raType: '',
    revenueAccountCode: '',
    costCenterCode: '',
    productServiceCode: '',
    salesChannelCode: '',
    projectCode: '',
    spareCode: '',
    activeInd: 'Y',
    mappingType: "TariffCOAMappingEntity",

}
