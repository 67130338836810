import { useTariffCoaMappingVM } from "presentation/hook/TariffCoaMapping/useTariffCoaMappingVM";
import { useTariffCoaMappingTracked } from "presentation/store/TariffCoaMapping/TariffCoaMappingProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import TariffCoaMappingEditPanel from "./RightPanel/TariffCoaMappingEditPanel";
import TariffCoaMappingTablePanel from "./Table/TariffCoaMappingTablePanel";


const TariffCoaMappingMaintenance:React.FC = () => {
    const [tariffCoaMappingState] = useTariffCoaMappingTracked();
    const [isLoading, setIsLoading] = useState(true);
    const tariffCoaMappingVM = useTariffCoaMappingVM();
    const {isShowEditPanel} = tariffCoaMappingState;


    useEffect(() => {
        const initialScreen = async() => {   
            try {     
                const results = await Promise.allSettled([
                    tariffCoaMappingVM.loadDropdownOption(),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {
                        
                    } 
                })
            } catch(error) {
                setIsLoading(false);
            }
        }
        initialScreen().then((data) => {            
            tariffCoaMappingVM.searchAllTariffCoaMappingList().then((data) => {            
                setIsLoading(false)
            }).catch(error => {            
                setIsLoading(false)
            });
        }).catch(error => {            
            setIsLoading(false)
        });
        
    }, [tariffCoaMappingVM])
    
    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>
        <div className={`main-comp-wrapper im-hide-side-form-draggable`}>
        
        {!(isShowEditPanel)&&<>
            <TariffCoaMappingTablePanel/></>}
        {(isShowEditPanel)&&
            <SliderPanel
                isOpen={true}
                draggable={false}
                leftSectionWidth={"100%"}
                rightSectionWidth={"0%"}
                leftChildren={<TariffCoaMappingEditPanel/>}
                />}
        </div>
    </>
}

export default memo(TariffCoaMappingMaintenance);