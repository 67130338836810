import { CustomCellRendererProps } from "ag-grid-react";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { TariffCoaMappingConstant } from "./TariffCoaMappingConstant";

const TARIFF_COA_MAPPING_CONSTANT = TariffCoaMappingConstant.Table;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
export const INITIAL_TARIFF_COA_MAPPING_COL_DEF: any[] = [

    {
        headerName: TARIFF_COA_MAPPING_CONSTANT.TARIFF_TYPE,
        field: "tariffType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
        // pinned: "left",
        // checkboxSelection: true,
        // headerCheckboxSelection: true,
    },
    {
        headerName: TARIFF_COA_MAPPING_CONSTANT.TARIFF_CODE,
        field: "tariffCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: TARIFF_COA_MAPPING_CONSTANT.RA_TYPE,
        field: "raType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 120,
    },
    {
        headerName: TARIFF_COA_MAPPING_CONSTANT.REVENUE_AC_CODE,
        field: "revenueAccountCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 190,
    },
    {
        headerName: TARIFF_COA_MAPPING_CONSTANT.COST_CENTER_CODE,
        field: "costCenterCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
    },
    {
        headerName: TARIFF_COA_MAPPING_CONSTANT.PRODUCT_SERVICE_CODE,
        field: "productServiceCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 230,
    },
    {
        headerName: TARIFF_COA_MAPPING_CONSTANT.SALES_CHANNEL_CODE,
        field: "salesChannelCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: TARIFF_COA_MAPPING_CONSTANT.PROJECT_CODE,
        field: "projectCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: TARIFF_COA_MAPPING_CONSTANT.SPARE_CODE,
        field: "spareCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },

    {
        headerName: TARIFF_COA_MAPPING_CONSTANT.ACTIVE_IND,
        field: "activeInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 130,
    },

].map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}