import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { TariffCoaMappingProvider } from "presentation/store/TariffCoaMapping/TariffCoaMappingProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import TariffCoaMappingMaintenance from "presentation/view/section/TariffCoaMapping/TariffCoaMappingMaintenance";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const TariffCoaMappingContMain = () => {
    return <ANAInfoWrapper permission={Permission.TARIFF_COA_MAPPING_MAINTENANCE}>
        <MessageBarWrapper>
            <TariffCoaMappingProvider>
                <GridStyles/>
                <TariffCoaMappingMaintenance/>
            </TariffCoaMappingProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default TariffCoaMappingContMain;