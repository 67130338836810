import { EMPTY_TARIFF_COA_MAPPING_ENTITY, TariffCoaMappingEntity } from "domain/entity/TariffCoaMapping/TariffCoaMappingEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface TariffCoaMappingDropdownOptions {
    tariffTypeDropdownOptions: DropdownProps[],
    tariffCodeDropdownOptions: { [key: string]: DropdownProps[] },
    raTypeDropdownOptions: DropdownProps[],
}
export interface TariffCoaMappingViewChangeState extends BaseViewChangeSate {
}

export interface TariffCoaMappingModel {
    isLoading:boolean,
    isShowEditPanel:boolean,
    isAdd:boolean,
    isEdit:boolean,
    dynamicOptions: TariffCoaMappingDropdownOptions,
    tariffCoaMappingList: TariffCoaMappingEntity[],
    selectedRows: TariffCoaMappingEntity[],
    currentRow: TariffCoaMappingEntity,
    currentEditRow: TariffCoaMappingEntity,
    
}

export const EMPTY_TARIFF_COA_MAPPING_MODEL: TariffCoaMappingModel = {
    isLoading: false,
    isShowEditPanel: false,
    isAdd: false,
    isEdit: false,
    dynamicOptions: {
        tariffTypeDropdownOptions: [],
        tariffCodeDropdownOptions: {},
        raTypeDropdownOptions: []
    },
    tariffCoaMappingList: [],
    selectedRows: [],
    currentRow: {...EMPTY_TARIFF_COA_MAPPING_ENTITY},
    currentEditRow: {...EMPTY_TARIFF_COA_MAPPING_ENTITY},
}