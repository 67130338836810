import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { TariffCoaMappingEntity } from "domain/entity/TariffCoaMapping/TariffCoaMappingEntity";
import { axiosGetData, axiosPostData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { TariffCoaMappingRepository } from "./TariffCoaMappingRepo";

export const TariffCoaMappingRepoImpl = (): TariffCoaMappingRepository => {
    
    const searchUrl = "/v1/searchAllTariffCoaMappingList"

    const searchAllTariffCoaMappingList = async() : Promise<TariffCoaMappingEntity[]> => {
        
        return axiosGetData(referenceAxiosInstance,`${searchUrl}`,[]).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const onSave = async(row: TariffCoaMappingEntity): Promise<ResponseEntity> => {
        const saveUrl = "/v1/saveTariffCOAMapping"
        return await axiosPostData(referenceAxiosInstance, `${saveUrl}`, row);
    }

    return {
        onSave: onSave,
        searchAllTariffCoaMappingList : searchAllTariffCoaMappingList,
    }
}
